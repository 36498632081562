<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.featuredAgent.list')"
    class="px-5 py-3"
  >
    <div class="mt-2">{{ $t('featuredAgent.viewTotal') }}: {{ meta.total }}</div>
    <div class="d-flex" style="padding-right: 14px; margin-top: -80px">
      <div style="flex: 0 0 75%; max-width: 75%"></div>
      <v-text-field
        type="text"
        outlined
        hide-details
        v-model="area"
        placeholder="Nama Area"
        @input="debounceSearchArea"
        style="flex: 0 0 25%; max-width: 25%"
      />
    </div>
    <div class="d-flex" style="margin-top: 68px">
      <base-dropdown-filter
        :items="months"
        @changeFilter="changeMonthFilter"
        style="flex: 0 0 75%; max-width: 75%"
        label="Bulan"
      >
      </base-dropdown-filter>
      <base-dropdown-filter
        :items="years"
        @changeFilter="changeYearFilter"
        style="flex: 0 0 25%; max-width: 25%"
        label="Tahun"
      >
      </base-dropdown-filter>
    </div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">No.</th>
          <th class="primary--text">
            {{ $t('featuredAgent.uuid') }}
          </th>
          <th class="primary--text">
            {{ $t('featuredAgent.area') }}
          </th>
          <th class="primary--text">
            {{ $t('featuredAgent.month') }}
          </th>
          <th class="primary--text">
            {{ $t('featuredAgent.year') }}
          </th>
          <th class="primary--text">
            {{ $t('featuredAgent.createdAt') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="`coin-usage-${index}`">
          <td>{{ index + 1 }}</td>
          <td>{{ item.uuid }}</td>
          <td>{{ item.area }}</td>
          <td>{{ item.month_name }}</td>
          <td>{{ item.year }}</td>
          <td>
            {{ item.created_at ? format(new Date(item.created_at), 'dd MMMM yyyy HH:mm') : '' }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
import LodashMixin from '@/mixins/lodash';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin, LodashMixin],
  props: ['items', 'meta', 'permissions', 'agentUuid'],
  components: {
    Pagination,
  },
  data() {
    return {
      format,
      area: null,
      debounceSearchArea: null,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.featuredAgent.basePath,
    }),
    months() {
      return this.range(12, 1);
    },
    years() {
      return this.range(100, 2025);
    },
  },
  created() {
    this.debounceSearchArea = this.debounce(this.changeAreaFilter, 700);
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeAreaFilter(val) {
      this.$emit('changeAreaFilter', val);
    },
    changeMonthFilter(val) {
      this.$emit('changeMonthFilter', val);
    },
    changeYearFilter(val) {
      this.$emit('changeYearFilter', val);
    },
  },
};
</script>
